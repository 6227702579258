import { LoadingSpinner, WrapXs2a } from '@/components/Generic'
import { observer } from 'mobx-react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import { StoreContext } from '@/components/App'
import { axiosInstance } from '@/methods/axiosConfig'
import { devLog } from '@/methods/devLog'
import { forceRedirect } from '@/methods/forceRedirect'
import { Container } from '@/styles/xs2aForm.styles'

interface GetStatusResponse {
  status: 'pending' | string
  expiringAtSeconds: number
  timeToExpirationSeconds: number
}

export const MicropaymentFintecAmexPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { xs2aSessionKey } = store.AppState
  const { currentScenarioId, context } = store.ScenarioState
  const { language } = store.TranslationsState

  const [sdkInit, setSdkInit] = useState(false)
  const apiUrl = process.env.WEB_API_URL
  const tinkUrl = new URL(context.startSessionUrl).origin

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const xs2a = (window as any).xs2a

  const [expirationTime, setExpirationTime] = useState(-1)

  useInterval(async () => {
    try {
      const { data } = await axiosInstance.get<GetStatusResponse>(
        `${tinkUrl}/tink/get-status`,
        {
          withCredentials: true,
        }
      )

      if (data.status !== 'pending') {
        store.TrackingState.disposeKeepSessionAliveInterval()
        xs2a.abortTransaction()
      }

      const expTime = data.expiringAtSeconds * 1000

      setExpirationTime(expTime)
    } catch (error) {
      devLog(error)
    }
  }, 60000)

  useInterval(() => {
    if (expirationTime === -1) return

    const now = new Date().getTime()
    if (now > expirationTime) {
      store.TrackingState.disposeKeepSessionAliveInterval()
      xs2a.abortTransaction()
    }
  }, 1000)

  const getInit = async () => {
    try {
      const startSessionResponse = await axiosInstance.post(
        context.startSessionUrl,
        {},
        {
          withCredentials: true,
        }
      )

      store.AppState.setXs2aSessionKey(
        startSessionResponse.data.wizardSessionKey
      )
    } catch (e) {
      devLog(e)
    }
  }

  const initSdk = async (): Promise<void> => {
    try {
      setSdkInit(true)
      const xs2sElement = document.getElementById('XS2A-Form')
      if (xs2aSessionKey && xs2aSessionKey.length && xs2a) {
        xs2a.lang(language)
        xs2sElement!.setAttribute('data-xs2a', xs2aSessionKey)
        handleXs2aEvents()
        xs2a.init()
      }
    } catch (e) {
      throw e
    }
  }

  const postTelemetry = useCallback(
    async (selectedBankName, selectedBankBIC) => {
      if (currentScenarioId !== 'localTests') {
        try {
          await axiosInstance.post(
            `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/audit-log-gateway`,
            {
              type: 'event.onboarding-web.tink.BANK_SELECTED',
              payload: {
                bankName: selectedBankName,
                bic: selectedBankBIC,
              },
            },
            {
              withCredentials: true,
            }
          )
        } catch (e) {
          devLog(e)
        }
      }
    },
    []
  )

  const handleXs2aEvents = () => {
    xs2a.finish(function () {
      handleComplete('success')
    })

    xs2a.abort(function () {
      handleComplete('error', 'aborted')
    })

    xs2a.error(function (errorCode, messages, recoverable) {
      if (!recoverable) {
        handleComplete('error', errorCode)
      }
    })

    xs2a.intermission(function () {
      handleIntermission()
    })

    xs2a.login(function () {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const bankInput = document?.getElementById(
        'XS2A-bank_code'
      ) as HTMLInputElement

      const bankData = document?.getElementsByClassName(
        'xs2a-completion-result-active'
      )[0] as HTMLElement

      const bankName = bankData
        ?.getElementsByClassName('xs2a-result-line1')[0]
        ?.textContent?.replace(/\s+/g, '')

      const bicHTML = bankData
        ?.getElementsByClassName('xs2a-result-line2')[0]
        ?.textContent?.replace(/\s+/g, '')
      const bankBIC = bicHTML?.substring(
        bicHTML?.indexOf('(') + 1,
        bicHTML?.lastIndexOf(')')
      )

      postTelemetry(bankName, bankBIC)
    })
  }

  const handleComplete = (status: string, error?: string): void => {
    if (error) {
      forceRedirect(
        `${apiUrl}/micropayment/complete?status=${status}&error=${error}`
      )
    } else {
      forceRedirect(`${apiUrl}/micropayment/complete?status=${status}`)
    }
  }

  const handleIntermission = () => {
    try {
      axiosInstance.get(`${apiUrl}/micropayment/continue`, {
        withCredentials: true,
      })
    } catch (e) {
      throw e
    }
  }

  useEffect(() => {
    if (xs2aSessionKey !== '') {
      initSdk()
    }
  }, [xs2aSessionKey])

  useEffect(() => {
    getInit()
  }, [])

  return (
    <Container
      {...theme.container}
      width="500px"
      padding="40px"
      paddingMobile="20px"
      marginMobile="0px"
    >
      <WrapXs2a
        display={sdkInit ? 'visible' : 'none'}
        input={theme.wrapXs2a.input}
        button={theme.wrapXs2a.button}
        {...theme.wrapXs2a}
      />
      {!sdkInit && (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      )}
    </Container>
  )
})
